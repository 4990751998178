<template>
  <div class="odn-form-field" :class="{ 'odn-form-field-error': error }">
    <ion-item v-if="!hasAltLabelSlot" lines="none">
      <ion-label :position="labelPosition"> {{ label }} </ion-label>
      <slot />
    </ion-item>
    <div v-if="hasAltLabelSlot || hasAltContentSlot" class="odn-form-field-alt">
      <div v-if="hasAltLabelSlot" class="odn-form-field-alt-label">
        <slot name="alt-label" />
      </div>
      <div v-if="hasAltContentSlot" class="odn-form-field-alt-content">
        <slot name="alt-content" />
      </div>
    </div>
    <div v-if="error" class="odn-form-field-error-message">
      <ion-icon :icon="icons.warning"></ion-icon>
      {{ error }}
    </div>
  </div>
</template>

<script>
import { IonItem, IonLabel, IonIcon } from '@ionic/vue';
import { warning } from 'ionicons/icons';

export default {
  components: {
    IonItem,
    IonLabel,
    IonIcon,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    labelPosition: {
      type: String,
      default: 'stacked',
    },
  },
  data() {
    return {
      icons: { warning },
    };
  },
  computed: {
    hasAltLabelSlot() {
      return !!this.$slots['alt-label'];
    },
    hasAltContentSlot() {
      return !!this.$slots['alt-content'];
    },
  },
};
</script>

<style lang="scss">
.odn-form-field {
  padding: 8px;
  background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &-alt {
    padding: 8px 16px;

    &-label {
      font-size: 12px;
    }
  }

  &-error {
    background-color: rgba(var(--ion-color-danger-rgb), 0.2);
  }

  &-error-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 16px 16px;
    font-size: 0.8em;
    color: var(--ion-color-danger);

    > ion-icon {
      margin-right: 0.5rem;
    }
  }

  > .ion-focused {
    border-radius: 6px;
  }

  > ion-item {
    --background: transparent;
  }

  img,
  video {
    display: block;
    width: calc(100% - 6px);
    margin: 0 auto 8px auto;
    border-radius: 4px;

    height: auto;
    max-height: 164px;
    object-fit: contain;
  }

  ion-button {
    margin-top: 8px;
  }
}
</style>
